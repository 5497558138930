import { Audit } from './audit/Audit';
import Vendor from './Vendor';
import { PurchaseOrderItem } from './PurchaseOrderItem';
import Shipment from './Shipment';
import Comment from './Comment';
import Currency from './Currency';
import Costs from './Costs';
import Payment from './Payment';
import PurchaseOrderOptions from './PurchaseOrderOptions';
import { PoProductRef } from './ref/PoProductRef';
import { PoUserRef } from './ref/PoUserRef';
import { ErrorWithStatusCode } from '../obj/errors/ErrorWithStatusCode';
import round from '../domain/round';

class PurchaseOrder{
    constructor({_id, id, ref=null, orderNumber="", vendor={}, purchaseDate=new Date(),
                    costs={}, currency={}, items=[], shipments=[], comments=[], payment={}, audit, options, meta={}}){
        this.id = id || (_id ? _id.toString() : null);
        this.ref = ref;

        this.orderNumber = orderNumber.trim();
        this.vendor = new Vendor(vendor);
        this.audit = new Audit(audit);
        this.purchaseDate = new Date(purchaseDate);
        this.costs = new Costs(costs, {poRef: this});
        this.payment = new Payment(payment);
        this.currency = new Currency(currency);

        let productIdSet = new Set();
        let builtItems = [];
        for (let i = 0; i < items.length; i++) {
            if(productIdSet.has(items[i].product.id))
                throw new Error("Purchase Order contains duplicate items for the same product id");
            productIdSet.add(items[i].product.id);
            builtItems.push(new PurchaseOrderItem(items[i], {poRef: this}), );
        }
        this.items = builtItems;

        let builtShipments = [];
        for (let i = 0; i < shipments.length; i++) {
            builtShipments.push(new Shipment(shipments[i]));
        }
        this.shipments = builtShipments;

        let builtComments = [];
        for (let i = 0; i < comments.length; i++) {
            builtComments.push(new Comment(comments[i]) );
        }
        this.comments = builtComments;
        this.options = new PurchaseOrderOptions(options);
        this.meta = meta;
    }

    static defaultNew({userId}){
        return new PurchaseOrder({audit: {updated: {userId: userId}}});
    }

    get totals(){
        let totals = {
            costs: this.costs.total,
            itemSubtotal: round(this.items.reduce((accumulator, poItem) => accumulator + (poItem.quantity * poItem.unitPrice), 0)),
            unitCount: this.items.reduce((accumulator, poItem) => accumulator + poItem.quantity, 0),

            grand: 0
        };
        totals.grand = round(totals.costs + totals.itemSubtotal);

        return totals;
    }

    get receivingTotals(){
        const totals = {
            receivedUnits: this.items.reduce((accumulator, poItem) => accumulator + poItem.receivingTotals.unitsReceived, 0),
            additionalExpectedUnits: this.items.reduce((accumulator, poItem) => accumulator + poItem.receivingTotals.additionalExpectedUnits, 0),
            openReceivingItems: this.items.reduce((accumulator, poItem) => accumulator + poItem.receivingTotals.openReceivingItems, 0),
        };

        totals.fullyReceived = totals.openReceivingItems === 0;
        totals.partiallyReceived = totals.receivedUnits > 0;
        return totals;
    }

    get shippingCostAllocationPercentage(){
        let total = 0;
        this.items.forEach(item => total = total + item.shippingCostAllocationPercentage);
        return round(total);
    }

    get isShippingCostAllocationValid(){
        let totalAllocatedPercent = this.shippingCostAllocationPercentage;
        if(this.costs.shipping === 0){
            if( totalAllocatedPercent !== 0) //No shipping cost but something allocated. Set all allocations to zero
                this.clearAllShippingCostAllocations();
            return true;
        }
        return totalAllocatedPercent === 1; //Otherwise, only valid if all cost allocated
    }

    get isValid(){
        for (let i = 0; i < this.items.length; i++) {
            if(!this.items[i].isValid)
                return false;
        }
        return this.isShippingCostAllocationValid;
    }

    get nonZeroPoItems(){
        return this.items.filter(item => item.quantity > 0);
    }

    clearAllShippingCostAllocations(){
        this.items.forEach((item, index, array) => array[index].shippingCostAllocationPercentage = 0 );
    }

    autoShippingCostAllocation(){
        if(this.items.length === 0) return; //Nothing to do

        let {unitCount} = this.totals;
        let unitPercentageWeight = 1/unitCount;
        let total = 0;
        this.items.forEach(poItem => {
            let allocation = parseFloat((poItem.quantity * unitPercentageWeight).toFixed(4));
            poItem.shippingCostAllocationPercentage = allocation;
            total += allocation;
        });
        //Adjust for rounding so total is 1
        this.items[0].shippingCostAllocationPercentage += 1 - total;
    }

    getNewPurchaseOrderItemsTransactionsBatch({userId}) {
        let batch = {transactions: [], userId: userId};
        this.items.forEach(item => {
            if(item.inboundTransactionId)
                return; //Only batch create brand new PO items that have never had a transaction
            let transaction = item.getInboundTransactionParams({userId: userId});
            if(transaction) //Ignores null which should not be added to batch
                batch.transactions.push(transaction)
        });
        if(batch.transactions.length === 0)
            return null; //Nothing to do
        return batch;
    }

    //todo move
    async updateAndValidateProductDataForItems({productsClient}){
        let promises = this.items.map(item => productsClient.getProductById(item.product.id));
        let productsDict;
        try{
            let products = await Promise.all(promises);
            productsDict = {};
            products.forEach(p => productsDict[p.key.id] = p);
        }catch (e) {
            const message = `Error updating and validating products in this PO from products service. One of more requests failed.`;
            if(e.message) e.message = message + e.message;
            else e.message = message;
            throw(message);
        }

        this.items.forEach(item => {
            let product = productsDict[item.product.id];
            item.updateProductRefFromProduct(product);
            if(!item.manualCountRules)
                item.initManualCountRules(product);
        });
    }

    //todo move
    async updateInboundTransactions({transactionClient, userId, log}){
        let promises = [];
        this.items.forEach(item => {
            promises.push(item.updateInboundTransaction({ transactionClient: transactionClient, userId: userId, log: log}));
        });
        return Promise.all(promises);
    }

    populateShipmentTrackingsFromTrackingsDictionary(trackingsDict){
        this.shipments.forEach(shipment => shipment.tracking = trackingsDict[shipment.slugTrackingString]);
    }

    //todo move
    async populateShipmentTrackings({trackingsService, log}){
        let promises = [];
        this.shipments.forEach(shipment => promises.push(shipment.setTrackingViaInjectionOfTrackingsService({trackingsService: trackingsService, log: log})));
        return Promise.allSettled(promises)
            .then(results => {
                return this;
            })
    }

    //todo move
    async createShipmentTrackingsInTrackingSystem({trackingsService}){
        let promises = [];
        this.shipments.forEach(shipment => promises.push(shipment.createTrackingViaInjectionOfTrackingsService(trackingsService)));
        return Promise.all(promises);
    }

    getPoItemForProductId(productId){
        let item = this.items.find(item => item.product.id === productId);
        return item || null;
    }

    //Note when a PO is actually saved each item will need to have its receiving set so that the reciving type can be defaulted
    //according to business rules
    addPurchaseOrderItem({productId, quantity, unitPrice, shippingCostAllocationPercentage=0}){
        let existingItemForProduct = this.getPoItemForProductId(productId);
        if(existingItemForProduct)
            throw new Error(`Cannot add item to Purchase Order. Product Id ${productId} already exists as a PO Item`);

        let productRef = new PoProductRef({id: productId});
        this.items.push(new PurchaseOrderItem({product: productRef, quantity: quantity, unitPrice: unitPrice,
            shippingCostAllocationPercentage: shippingCostAllocationPercentage}, {poRef: this}));
    }

    applyReceivingActions(actions){
        let itemsModified = {};
        let detailMessage = '';
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            const item = this.getPoItemForProductId(action.productId);
            if (!item)
                throw new ErrorWithStatusCode({
                    code: 404,
                    message: `Cannot find po item with productId K${action.productId}`
                });
            const itemMessage = item.applyReceivingAction(action);
            itemsModified[item.id] = true;
            if (detailMessage !== '')
                detailMessage = detailMessage + '\r\n';
            detailMessage = detailMessage + `   ${i + 1}) ${itemMessage}`;
        }
        const keys = Object.keys(itemsModified);
        let message = `Purchase Order ${this.ref}: ${actions.length} receiving action${actions.length !== 1 ? 's' : ''} applied to ${keys.length} PO Item${keys.length !== 1 ? 's' : ''}`;
        return {message: message, detailMessage: detailMessage};
    }

    applyVoidReceivingActions(voidActions){
        let itemsModified = {};
        let transactionIdsToVoid = [];
        for (let i = 0; i < voidActions.length; i++) {
            const action = voidActions[i];
            const item = this.getPoItemForProductId(action.productId);
            if (!item)
                throw new ErrorWithStatusCode({
                    code: 404,
                    message: `Cannot find po item with productId K${action.productId}`
                });
            transactionIdsToVoid = transactionIdsToVoid.concat(item.voidReceivingItem(action));
            itemsModified[item.id] = true;
        }
        const keys = Object.keys(itemsModified);
        let message = `Purchase Order ${this.ref}: ${voidActions.length} receiving item${voidActions.length !== 1 ? 's' : ''} voided. ${keys.length} PO Item${keys.length !== 1 ? 's' : ''} modified`;
        return {message: message, transactionIdsToVoid: transactionIdsToVoid};
    }

    /**
     *
     * @param userId
     * @param productsDict Dictionary by product id that will be used to determine reserve target and current reserve inventory level.
     * Missing products are ignored meaning no reserve add transaction will be created.
     * @param logger Optional logger to log missing products
     * @return {{transactions: Array}}
     */
    getReceivingTransactionsBatch({userId, productsDict}, {logger}={}){
        let transactions = [];
        for (let i = 0; i < this.items.length; i++) {
            const poItem = this.items[i];
            const product = productsDict[poItem.product.id];
            let [reserveTargetCount, currentReserveCount] = [0, 0];
            if(product)
                [reserveTargetCount, currentReserveCount] = [product.targets.storeReservedTarget, product.counters.getCounter('storeReserved')];
            else{
                if(logger) logger.warning(`Get Receiving Transactions Batch: Missing product id K${poItem.product.id} in dictionary. No reserve transactions will be created.` +
                    ` PO Item ${poItem.id}, Product Id ${poItem.product.id}`)
            }

            if(this.options.ignoreReserveTargets)
                reserveTargetCount = 0; //Ignore reserve for this PO

            transactions = transactions.concat(poItem.getNewTransactionsForReceivedItems({reserveTargetCount: reserveTargetCount, currentReserveCount: currentReserveCount}));
        }
        transactions.forEach(t => t.userId = userId);
        return {
            transactions: transactions,
            userId: userId
        };
    }

    removeUnsavedPoItemsWithAZeroQuantity(){
        this.items = this.items.filter(item => !(item.quantity === 0 && item.isUnsaved));
    }

    //todo move
    verifyReceivingItemsUnchangedExceptForExpectedQuantityFromSourcePo(sourcePo){
        if(sourcePo.id !== this.id || sourcePo.ref !== this.ref)
            throw new Error("Cannot complete operation. POs must be the same");

        for (let i = 0; i < this.items.length; i++) {
            const thisItem = this.items[i];
            const sourceItem = sourcePo.getPoItemForProductId(thisItem.product.id);
            if(!sourceItem){
                //New. Need to make sure receiving items only have expected quantities
                thisItem._validateAllReceivingItemsAreNew();
                continue;
            }

            //Base
            if(!thisItem.baseReceivingItem.isEqualWithoutExpectedQuantity(sourceItem.baseReceivingItem))
                throw new ErrorWithStatusCode({code: 400, message: "Base receiving items not equal. Receiving cannot be updated"});

            for (let j = 0; j < thisItem.receivingItems.length ; j++) {
                const thisReceivingItem = thisItem.receivingItems[j];
                const sourceReceivingItem = sourceItem._getReceivingItemForShipmentId(thisReceivingItem.shipmentId);
                if(!sourceReceivingItem){
                    //New shipment allocation
                    thisReceivingItem._validateReceivingItemIsNew();
                    continue;
                }

                if(!thisReceivingItem.isEqualWithoutExpectedQuantity(sourceReceivingItem))
                    throw new ErrorWithStatusCode({code: 400, message: "A receiving item is not equal. Receiving cannot be updated"});
            }
        }
    }

    //todo move
    _validateAllReceivingItemsAreNew(){
        for (let i = 0; i < this.items.length; i++) {
            const item = this.items[i];
            item._validateAllReceivingItemsAreNew();

            for (let j = 0; j < item.receivingItems.length ; j++) {
                const receivingItem = item.receivingItems[j];
                receivingItem._validateReceivingItemIsNew();
            }
        }
    }

    assertNoDisallowedChangesToPoItems(sourcePo){
        this._assertNoPoItemsHaveBeenRemoved(sourcePo);
        for (let i = 0; i < sourcePo.items.length; i++) {
            const sourceItem = sourcePo.items[i];
            const thisItem = this.items.find(i => i.id === sourceItem.id);
            thisItem.assertNoDisallowedChanges(sourceItem);
        }
    }

    _assertNoPoItemsHaveBeenRemoved(sourcePo){
        for (let i = 0; i < sourcePo.items.length; i++) {
            const sourceItem = sourcePo.items[i];
            const thisItem = this.items.find(i => i.id === sourceItem.id);
            if(!thisItem)
                throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot be removed. Missing PO Item with ID ${sourceItem.id}`});
        }
    }

    assertNoDisallowedChangesToComments(sourcePo){
        for (let i = 0; i < sourcePo.comments.length; i++) {
            const sourceComment = sourcePo.comments[i];
            const thisComment = this.comments.find(c => c.id === sourceComment.id);
            if(!thisComment)
                throw new ErrorWithStatusCode({code: 400, message: `PO Comments cannot be removed. Missing PO Comment with ID ${sourceComment.id}`});
            if(!thisComment.equals(sourceComment))
                throw new ErrorWithStatusCode({code: 400, message: `PO Comments cannot be modified after they are saved`});
        }
    }

    getShipment({trackingNumber, shipmentId}){
        let shipment = null;
        if(trackingNumber)
            shipment = this.shipments.find(shipment => shipment.trackingNumber === trackingNumber);
        else if(shipmentId)
            shipment = this.shipments.find(shipment => shipment.id === shipmentId);
        else
            shipment = this.shipments.find(shipment => shipment.id === shipmentId && shipment.trackingNumber === trackingNumber);
        return shipment
    }

    //By default shipments are added with no items. When all shipments have no items then the items are considered to be allocated
    //at the PO level. A minimum of one shipment must be checked in in order to mark all items as checked in as well.
    addShipment({trackingNumber, slug}){
        let existingShipment = this.getShipment({trackingNumber: trackingNumber});
        if(existingShipment)
            throw new Error(`Cannot add shipment with the same tracking number to this PO. Tracking number ${trackingNumber} already exists on this PO`);

        this.shipments.push(new Shipment({trackingNumber: trackingNumber, slug: slug}));
    }

    removeShipmentByTrackingNumber(trackingNumber){
        for( let i = 0; i < this.items.length; i++){
            if ( this.shipments[i].trackingNumber === trackingNumber) {
                this.shipments.splice(i, 1);
                return;
            }
        }
    }

    addComment({comment, userId, username, displayName, showOnReceiving}){
        let userRef = new PoUserRef({username: username, id: userId, displayName: displayName});
        this.comments.push(new Comment({comment: comment, user: userRef, showOnReceiving}));
    }

    toString(){
        const totals = this.totals;
        return `${this.orderNumber}, ${this.vendor.name}, ${this.items.length} Product${this.items.length !== 1 ? 's' : ''} for ${totals.unitCount} Units, $${totals.grand.toFixed(2)}`
    }

    setCreatedUserId(userId){
        this.audit.created.userId = userId;
        this.audit.created.date = new Date();
        this.setUpdatedUserId(userId, this.audit.created.date)
    }

    setUpdatedUserId(userId, date=new Date()){
        this.audit.updated.userId = userId;
        this.audit.updated.date = date;
    }

    //Due to how simple this needs to be and that would not work out of the box with existing Sanitizable just doing it here
    sanitizeFromUser(user){
        if(user.isMemberOfGroup("IM_FINANCES"))
            return this.toJSON(); //No need to do anything
        //Remove finances data
        const copy = new PurchaseOrder(this);
        copy.costs.other = 0;
        copy.costs.shipping = 0;
        copy.items.forEach(item => item.unitPrice = 0);
        return copy.toJSON();
    }

    toJSON(){
        let res = Object.assign({}, this);
        res.totals = this.totals;
        res.receivingTotals = this.receivingTotals;
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        res.costs = this.costs.getForDb();
        res.items = this.items.map(item => item.getForDb());
        res.shipments = this.shipments.map(shipment => shipment.getForDb());
        delete res.id;
        return res;
    }

    getForDbReceivingUpdate(){
        let copy = Object.assign({}, this);
        let res = {items: [], receivingTotals: this.receivingTotals};
        copy.items.forEach(item => {
            res.items.push(item.getForDbReceivingUpdate())
        });
        return res;
    }
}

export { PurchaseOrder };